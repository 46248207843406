@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.ant-picker {
    z-index: 11600 !important;
    padding: 0.7em !important;
}

.active-row .ant-table-cell-fix-right {
    background-color: transparent !important;
}

.ant-picker-panel-container {
    z-index: 11600 !important;
}

.ant-picker-panel-layout {
    z-index: 11600 !important;
}

.ant-picker-dropdown {
    z-index: 11600 !important;
}

.custom-modal {
    z-index: 10000;
}

.custom-modal .modal-header {
    border: none;
}

.custom-modal .modal-title {
    font-size: 1.1em;
    font-weight: 700;
}

.crosshair {
    top: 0;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    border: 27em solid rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset;
    position: absolute;
    width: 87%;
    padding-bottom: 25em;
    margin-top: -280px;
    margin-left: 180px;
    height: 100%;
}

.tool {
    position: absolute;
    z-index: 999999;
    width: 80vw;
    top: 90%;
    height: 25em;
    background: #f9fafb;
    right: 0;
}

.crosshair-full {
    top: -50%;
    left: 0;
    z-index: 1;
    box-sizing: border-box;
    border: 30em solid rgba(0, 0, 0, 0.3);
    box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset;
    position: absolute;
    width: 100%;
    padding-bottom: 30em;
    /* margin-top: -320px; */
    height: 100vh;
}

@media all and (display-mode: fullscreen) {

    /* every CSS goes here that you want 
    to apply or alter in the fullscreen mode*/
    .crosshair-full {
        top: -30% !important;
        left: 0;
    }

    body {
        overflow: hidden !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 786px) {
    .crosshair {
        width: 100% !important;
        height: 100%;
    }
}

/*video {*/
/*    overflow: hidden !important;*/
/*    width: 100%;*/
/*    height: 100vh !important;*/
/*    object-fit: cover !important;*/
/*}*/

.info-qr-content {
    padding: 0 30px 30px 30px;
    position: relative;
    line-height: 2;
}

.text-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
}

.text-row .label-text {
    font-weight: 700;
    width: 70%;
}

.text-row .filled-text {
    text-align: right;
    width: 50%;
}

.ant-message-top {
    z-index: 11000 !important;
}

.ant-select-selector {
    z-index: 9999 !important;
}

.ant-select-selection-item {
    z-index: 9999 !important;
}

.ant-select-select-open {
    z-index: 9999 !important;
}

.modal-backdrop.show {
    z-index: 10000 !important;
    opacity: 0.8 !important;
}

.name-tag-modal {
    /*width : 535px*/
}

.MuiTablePagination-selectLabel {
    margin: 0 !important;
}

.MuiTablePagination-displayedRows {
    margin: 0 !important;
}

.hide {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}

.tooltip-custom {
    position: relative;
    border-bottom: 1px dotted #888;
}

.tooltip-custom .tooltiptext {
    visibility: visible;
    width: 220px;
    background-color: #888;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
}

.tooltip-custom .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 20%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #888 transparent transparent transparent;
}

.manual-qr-box {
    width: 24rem;
    height: 48rem;
    flex-shrink: 0;
    border-radius: 3rem;
    background: #FFFFFF;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

.manual-qr-box .qr-content {
    margin: 0 auto
}

.manual-qr-box .qr-content {
    margin: 0 auto;
    text-align: center;
}

.input-qr-content {
    margin: 0 auto;
}

.input-qr-title {
    font-weight: 600;
    font-size: 36px;
    text-align: center
}

.input-qr-subtitle {
    font-size: 20px;
    margin: 0 0 0 6.5rem;
}

.input-qr-form {
    position: relative;
    width: calc(100% - 200px);
    left: 15%;
}

.input-qr-submit-button {
    margin: 0 0 0 6.5rem;
    width: calc(100% - 200px)
}

/* Small devices (portrait phones) */
@media (max-width: 576px) {

    .manual-qr-box {
        width: 280px;
        top: 5%;
        height: 550px;
    }

    .input-qr-subtitle {
        font-size: 16px;
        margin: 0;
        text-align: center;
    }

    .input-qr-form {
        position: static;
        width: 100%;

    }

    .input-qr-submit-button {
        margin: 0;
        width: 100%;
    }

}

/* Small devices (landscape phones) */
@media (min-width:600px) and (max-width: 768px) {
    .manual-qr-box {
        width: 200px;
        top: 5%;
        height: 350px;

    }

    .input-qr-subtitle {
        font-size: 16px;
        margin: 0;
        text-align: center;
    }

    .input-qr-form {
        position: static;
        width: 100%;

    }

    .input-qr-submit-button {
        margin: 0;
        width: 100%;
    }
}

/*********************/
.right-container {
    display: flex;
    width: 44rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4.5rem;
}

.right-container-top-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.25rem;
}

.right-container-logo {
    color: #000;
    font-size: 2.25rem;
    font-family: "Bitter";
    font-style: normal;
    font-weight: 500;
    line-height: 2.75rem;
}

.right-container-text {
    color: rgba(0, 0, 0, 0.80);
    font-size: 1.75rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 2.25rem;
}

.right-container-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
}

.right-container-button {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
    border-radius: 0.375rem;
    background: var(--primary-200, #CE93D8);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    width: max-content;
}